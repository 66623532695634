.login {
    position: absolute;
    /* background-color: rgb(109, 19, 226); */
    height: 100%;
    width: 100%;
    /* color: white; */
}
.login .block {
    /* background-color: rgba(0, 0, 0, 0.5) !important; */
    width: 92%;
    height: 96%;
    margin: 4%;
}

.login .logo {
    text-align: center;
    font-size: 25px;
    /* padding-top: 50%; */
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
}

.login .login-form {
    /* margin-top: 15%; */
    width: 90%;
    max-width: 400px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login .MuiTextField-root {
    width: 80% !important;

}
.login .MuiTextField-root input {
    /* color: white; */
    border-bottom: 1px solid white !important;
}

.login .MuiFormLabel-root {
    /* color: white !important; */
}