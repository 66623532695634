.writing {
    width: 320px;
    height: 200px;
    background-color: #3f3f3f;
    border: 1px solid #bbbbbb;
    border-radius: 6px 6px 4px 4px;
    position: relative;
}
.writing .topbar{
    position: absolute;
    width: 100%;
    height: 12px;
    background-color: #f1f1f1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
    .writing .topbar div{
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin: 3px;
    float: left;
    }
    .writing .topbar div.green{
    background-color: #60d060;
    }
    .writing .topbar div.red{
    background-color: red;
    }
    .writing .topbar div.yellow{
    background-color: #e6c015;
    }
    .writing .code {
    padding: 15px;
    }
    .writing .code ul {
    list-style: none;
    margin: 0;
    padding: 0;
    }
    .writing .code ul li {
    background-color: #9e9e9e;
    width: 0;
    height: 7px;
    border-radius: 6px;
    margin: 10px 0;
    }
    .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
    transition: transform .5s;
    }
    .stack-container {
    position: relative;
    width: 420px;
    /* height: 210px; */
    transition: width 1s, height 1s;
    }
    .pokeup {
    transition: all .3s ease;
    }
    .pokeup:hover {
    transform: translateY(-10px);
    transition: .3s ease;
    }
    .error {
        /* margin-top: 10px; */
    /* width: 400px; */
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    height: 0px !important;
    text-align: center;
    }
    .error h1 {
        font-size: 125px;
        padding: 0;
        margin: 0;
        font-weight: 700;
    }
    .error h2 {
        margin: -30px 0 0 0;
        padding: 0px;
        font-size: 47px;
        letter-spacing: 12px;
    }
    .errorcode {
        color: gray;
        margin-top: 10px;
    /* width: 400px; */
    /* padding: 40px; */
    text-align: center;
    }
    .errorcode h1 {
    font-size: 80px;
    padding: 0;
    margin: -10px;
    font-weight: 700;
    }
    .errorcode h2 {
    margin: -10px 0 0 0;
    padding: 0px;
    font-size: 20px;
    letter-spacing: 12px;
    }
    .errorcode h3 {
        margin: 10px 0 0 0;
        padding: 0px;
        font-size: 15px;
        /* letter-spacing: 12px; */
    }
    .perspec {
    perspective: 1000px;
    }

    .writeLine{
    -webkit-animation: writeLine .4s linear forwards;
    animation: writeLine .4s linear forwards;
    }
    .explode{
    -webkit-animation: explode .5s ease-in-out forwards;
    animation: explode .5s ease-in-out forwards;
    }
    .card {
    -webkit-animation: tiltcard .5s ease-in-out 1s forwards;
    animation: tiltcard .5s ease-in-out 1s forwards;
    position: absolute;
    }
    @-webkit-keyframes tiltcard {
    0% {
    transform: rotateY(0deg);
    }
    100% {
    transform: rotateY(-30deg);
    }
    }
    @keyframes tiltcard {
    0% {
    transform: rotateY(0deg);
    }
    100% {
    transform: rotateY(-30deg);
    }
    }
    @-webkit-keyframes explode {
    0% {
    transform: translate(0, 0) scale(1);
    }
    100% {
    transform: translate(var(--spreaddist), var(--vertdist)) scale(var(--scaledist));
    }
    }
    @keyframes explode {
    0% {
    transform: translate(0, 0) scale(1);
    }
    100% {
    transform: translate(var(--spreaddist), var(--vertdist)) scale(var(--scaledist));
    }
    }
    @-webkit-keyframes writeLine {
    0% {
    width:0;
    }
    100% {
    width: var(--linelength);
    }
    }
    @keyframes writeLine {
    0% {
    width:0;
    }
    100% {
    width: var(--linelength);
    }
    }
    @media screen and (max-width: 1000px) {
    .container {
    transform: scale(.85);
    }
    }
    @media screen and (max-width: 850px) {
    .container {
    transform: scale(.65);
    }
    }
    @media screen and (max-width: 775px) {
    .container {
    flex-wrap: wrap-reverse;
    align-items: inherit;
    }
    }
    @media screen and (max-width: 370px) {
    .container {
    transform: scale(.6);
    }
    }